/* eslint-disable no-param-reassign */
import Component from './Component';

class ScrollingVideos extends Component {
  constructor(element, helper) {
    super(element, helper);

    const scrollingVideos = this;
    const firstVideoElement = element.querySelector('video');

    this.startAnimationPlayed = false;
    this.videoElements = element.querySelectorAll('video');

    function mapRange(number, inMin, inMax, outMin, outMax) {
      return (number - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
    }

    function onTimeupdate() {
      let newCurrentTime = firstVideoElement.duration * scrollingVideos.percentVideoTime;
      newCurrentTime = (newCurrentTime < 0) ? 0 : newCurrentTime;
      newCurrentTime = (newCurrentTime > firstVideoElement.duration) ? firstVideoElement.duration : newCurrentTime;
      if (firstVideoElement.currentTime >= newCurrentTime && !scrollingVideos.startAnimationPlayed) {
        scrollingVideos.startAnimationPlayed = true;
        [...scrollingVideos.videoElements].forEach(videoElement => videoElement.pause());
        scrollingVideos.currentTime = firstVideoElement.currentTime;
        firstVideoElement.removeEventListener('timeupdate', onTimeupdate);
      }
    }

    function onScroll() {
      if (scrollingVideos.elementIsInView()) {
        const { elementOffsetTop, elementOffsetHeight } = scrollingVideos;
        // Wo befindet sich die untere Line des Elements relativ zum Viewport: 1 = untere Viewport-Grenze, 0 = obere Viewport-Grenze
        const relativeBottomLine = ((elementOffsetTop + elementOffsetHeight) - document.scrollingElement.scrollTop) / window.innerHeight;
        let percentVideoTime = mapRange(relativeBottomLine, 1, 1.5, 0.8, 0);
        percentVideoTime = (percentVideoTime > 1) ? 1 : percentVideoTime;
        percentVideoTime = (percentVideoTime < 0) ? 0 : percentVideoTime;
        scrollingVideos.percentVideoTime = percentVideoTime;

        if (!scrollingVideos.startAnimationPlayed) {
          scrollingVideos.showAndPlay();
        }
      }
    }

    firstVideoElement.addEventListener('timeupdate', onTimeupdate);
    document.addEventListener('scroll', onScroll, {
      passive: true,
    });
  }

  elementIsInView() {
    const isInView = (this.helper.scrollTop + window.innerHeight > this.elementOffsetTop + this.elementOffsetHeight * 0.5
      && this.helper.scrollTop < this.elementOffsetTop + this.elementOffsetHeight);
    return isInView;
  }


  showAndPlay() {
    const { videoElements } = this;
    if (!this.startAnimationPlayed) {
      [...videoElements].forEach(videoElement => videoElement.play());
    }
  }


  draw() {
    function smoothValue(oldValue, newValue, speed = 0.1) {
      return (oldValue + (speed * (newValue - oldValue)));
    }
    const {
      videoElements,
      startAnimationPlayed,
      percentVideoTime,
      currentTime,
    } = this;
    if (startAnimationPlayed) {
      [...videoElements].forEach((videoElement) => {
        let newCurrentTime = ((videoElement.duration - 1.68) * percentVideoTime) + 1.68;
        newCurrentTime = (newCurrentTime < 0) ? 0 : newCurrentTime;
        newCurrentTime = (newCurrentTime > videoElement.duration) ? videoElement.duration : newCurrentTime;
        this.currentTime = newCurrentTime;
        this.smoothCurrentTime = smoothValue(currentTime, newCurrentTime, 0.2);
        if (currentTime !== this.smoothCurrentTime) {
          videoElement.currentTime = this.smoothCurrentTime;
        }
      });
    }
  }
}


export default ScrollingVideos;
