import Component from './Component';

class ImageFixed extends Component {
  constructor(element, helper) {
    super(element, helper);
    this.textElement = element.querySelector('.text');
    this.imageElement = element.querySelector('.image-fixed__image');
  }

  draw() {
    if (this.elementIsInView()) {
      const pixelTop = window.innerHeight * 0.3;
      const elementVisiblePx = (this.elementOffsetHeight + this.elementOffsetTop) - this.helper.scrollY;
      if (this.textElement) {
        // this.textElement.style.display = '';
      }
      if (elementVisiblePx < pixelTop) {
        const opacity = ((elementVisiblePx * 0.5) / pixelTop) + 0.5;
        this.imageElement.style.opacity = `${opacity}`;
      } else {
        this.imageElement.style.opacity = '';
      }
    }
  }
}

export default ImageFixed;
