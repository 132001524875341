/*
ImagesGrid ≙ Point Of Sale
parallax for the background image
*/

import Component from './Component';

class ImagesGrid extends Component {
  constructor(element, helper) {
    super(element, helper);

    this.y = 0;
    this.imageElements = element.querySelectorAll('.images-grid__image');
  }


  draw() {
    if (this.elementIsInView()) {
      const y = (1 - (this.helper.scrollCenterY - this.elementCenterY)) * -0.08 * (10 / 2);
      if (this.y !== y) {
        this.imageElements.forEach((element, index) => {
          const modification = (index + 1) / 100;
          element.style.transform = `translateY(${y * modification}%)`;
        });
      }
      this.y = y;
    }
  }
}

export default ImagesGrid;
