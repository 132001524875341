class VimeoVideo {
  constructor(element) {
    this.cookieBannerElement = element.querySelector('.vimeo-video__cookie-banner');
    this.iframeElement = element.querySelector('iframe');
    const rememberCheckboxElement = element.querySelector('input[name="remember"]');
    const buttonElement = element.querySelector('button');
    const provider = buttonElement.value;

    function showAllVideos() {
      document.vimeoVideos.forEach((vimeoVideo) => {
        vimeoVideo.show();
      });
    }

    if (localStorage.getItem(`${provider}_content`) === 'true') {
      this.show();
      // eslint-disable-next-line no-underscore-dangle
      window._paq?.push(['trackEvent', 'Video', 'Show automatic', this.iframeElement.dataset.src]);
    }

    element.addEventListener('submit', (event) => {
      event.preventDefault();
      if (rememberCheckboxElement.checked) {
        localStorage.setItem(`${provider}_content`, 'true');
        showAllVideos();
      }
      this.show();
      window._paq?.push(['trackEvent', 'Video', 'Show after click', this.iframeElement.dataset.src]);
    });
  }

  show() {
    const {
      iframeElement,
      cookieBannerElement,
    } = this;
    iframeElement.setAttribute('src', iframeElement.dataset.src);
    cookieBannerElement.setAttribute('hidden', '');
  }

}

export default VimeoVideo;
