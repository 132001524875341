class Next {
  constructor(element) {
    const videoElement = element.querySelector('video');

    function playBackwards() {
      videoElement.currentTime -= 1 / 60;
      if (videoElement.paused && videoElement.currentTime > 0) {
        requestAnimationFrame(playBackwards);
      }
    }

    function onMouseleave() {
      if (videoElement) {
        videoElement.pause();
        if (/Firefox/.test(navigator.userAgent)) { // Firefox can’t play backwards h264
          videoElement.currentTime = 0;
        } else {
          playBackwards();
        }
      }
    }
    function onMouseenter() {
      if (videoElement) {
        videoElement.play();
      }
    }
    element.addEventListener('mouseleave', onMouseleave);
    element.addEventListener('mouseenter', onMouseenter);
  }
}

export default Next;
