import Component from './Component';

class Slider3D extends Component {
  constructor(element, helper) {
    super(element, helper);

    const slider3d = this;
    let isLoaded = false;
    const pictureElements = element.querySelectorAll('picture');
    const headerElement = document.querySelector('.header');
    const mainnavElement = document.querySelector('.main-nav');
    let loadedImages = 0;
    const supportsClipPath = (window.CSS && (window.CSS.supports('clip-path', 'inset(0)') || window.CSS.supports('-webkit-clip-path', 'inset(0)')));

    this.headerElement = headerElement;
    this.mainnavElement = mainnavElement;
    this.headerElement = document.querySelector('.header');
    this.mainnavElement = document.querySelector('.main-nav');
    this.textElement = element.querySelector('.text');
    this.infoElement = element.querySelector('.slider-3d__info');
    this.imagesElement = element.querySelector('.slider-3d__images');
    this.pictureElements = pictureElements;
    this.hasUserScrolled = false;
    this.supportsClipPath = supportsClipPath;

    function animateHeader() {
      if (document.scrollingElement.scrollTop <= 0 && supportsClipPath) {
        const headerHeight = headerElement.offsetHeight;
        headerElement.style.transition = '400ms';
        headerElement.style.transform = `translateY(${-headerHeight}px)`;
        mainnavElement.style.transition = '400ms';
        mainnavElement.style.transform = `translateY(${-headerHeight}px)`;
        setTimeout(() => {
          headerElement.style.transition = '';
          mainnavElement.style.transition = '';
        }, 450);
      }
    }

    function onWheel() {
      document.scrollingElement.scrollTop = slider3d.virtualScrollTop;
      if (slider3d.textElement) {
        slider3d.textElement.style.transform = '';
      }
      slider3d.headerElement.style.transform = '';
      slider3d.mainnavElement.style.transform = '';
      window.removeEventListener('wheel', onWheel);
      window.removeEventListener('touchmove', onWheel);
      slider3d.hasUserScrolled = true;
    }
    function startAnimation() {
      if (slider3d.helper.scrollTop <= 0 && !slider3d.animationStartTime) {
        const to = (element.offsetHeight + element.offsetTop) - window.innerHeight;
        [...slider3d.pictureElements][0].style.willChange = '-webkit-clip-path, clip-path';
        [...slider3d.pictureElements][1].style.willChange = '-webkit-clip-path, clip-path';
        window.addEventListener('wheel', onWheel, { passive: true });
        window.addEventListener('touchmove', onWheel, { passive: true });
        if (slider3d.textElement) {
          slider3d.textElement.style.willChange = 'transform';
          slider3d.textElement.style.transform = 'translateY(200%)';
          slider3d.textElement.classList.remove('is-hidden');
        }
        slider3d.animationStartTime = Date.now();
        slider3d.animationScrollTo = to;
        animateHeader();
      } else if (slider3d.textElement) {
        slider3d.textElement.classList.remove('is-hidden');
      }
    }
    function onAllImagesLoaded() {
      isLoaded = true;
      [...pictureElements][0].classList.remove('is-hidden');
      element.classList.remove('is-loading');
      setTimeout(startAnimation, 500);
    }
    function onImageLoad() {
      loadedImages += 1;
      if (loadedImages === pictureElements.length) {
        onAllImagesLoaded();
      }
    }
    function checkIfLoaded() {
      [...pictureElements].forEach((pictureElement) => {
        const imgElement = pictureElement.querySelector('img');
        if (imgElement.complete && imgElement.offsetWidth > 0) {
          onImageLoad();
        } else {
          imgElement.addEventListener('load', onImageLoad);
        }
      });
    }

    if (element.classList.contains('is-first')) {
      checkIfLoaded();
      setTimeout(() => {
        if (!isLoaded) {
          [...pictureElements][0].classList.remove('is-hidden');
          element.classList.add('is-loading');
        }
      }, 500);
    } else if (slider3d.textElement) {
      slider3d.textElement.classList.remove('is-hidden');
    }
  }

  onResize() {
    super.onResize();
    if (this.element && this.textElement) {
      if (matchMedia('(max-width: 1024px)').matches && this.element.classList.contains('is-first')) {
        this.element.style.paddingBottom = `${this.textElement.offsetHeight}px`;
      }
    }
  }

  draw() {
    if (this.elementIsInView()) {
      this.virtualScrollTop = this.helper.scrollTop;
      this.element.style.overflow = '';
      if (this.textElement) {
        this.textElement.style.display = '';
      }
      if (this.infoElement) {
        this.infoElement.style.display = '';
      }

      if (this.animationStartTime && !this.hasUserScrolled) {
        const duration = 3000;
        let progress = (Date.now() - this.animationStartTime) / duration;
        progress = this.helper.easeInOutCubic(progress);
        const to = this.animationScrollTo;
        this.virtualScrollTop = (progress * to);
        if (!matchMedia('(max-width: 1024px)').matches && this.textElement) {
          this.textElement.style.transform = `translateY(${(1 - progress) * 200}%)`;
        }

        if (progress >= 1) { // start animation endend
          document.scrollingElement.scrollTop = this.virtualScrollTop;
          this.animationStartTime = false;
          if (this.textElement) {
            this.textElement.style.willChange = '';
            this.textElement.style.transform = '';
          }
          [...this.pictureElements][0].style.willChange = '';
          [...this.pictureElements][1].style.willChange = '';
          this.headerElement.style.transform = '';
          this.mainnavElement.style.transform = '';
        }
      }

      if (this.supportsClipPath) {
        let scrollTop1 = (this.virtualScrollTop - this.elementOffsetTop);
        if (scrollTop1 < 0) {
          scrollTop1 = 0;
        } else if (scrollTop1 > window.innerHeight) {
          scrollTop1 = window.innerHeight;
        }
        let scrollTop2 = (this.virtualScrollTop - window.innerHeight - this.elementOffsetTop);
        if (scrollTop2 < 0) {
          scrollTop2 = 0;
        } else if (scrollTop2 > window.innerHeight) {
          scrollTop2 = window.innerHeight;
        }
        [...this.pictureElements][0].style.webkitClipPath = `inset(0 0 ${scrollTop1}px 0)`;
        [...this.pictureElements][0].style.clipPath = `inset(0 0 ${scrollTop1}px 0)`;
        [...this.pictureElements][1].style.webkitClipPath = `inset(0 0 ${scrollTop2}px 0)`;
        [...this.pictureElements][1].style.clipPath = `inset(0 0 ${scrollTop2}px 0)`;
      } else {
        document.scrollingElement.scrollTop = this.virtualScrollTop;
      }
    } else {
      this.element.style.overflow = 'hidden';
      if (matchMedia('(min-width: 1025px)').matches) {
        if (this.textElement) {
          this.textElement.style.display = 'none';
        }
        if (this.infoElement) {
          this.infoElement.style.display = 'none';
        }
      }
    }
  }
}

export default Slider3D;
