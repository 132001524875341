/*
Pos ≙ Point Of Sale
parallax for the background image
*/

import Component from './Component';
// import PosText from './PosText';

class Pos extends Component {
  constructor(element, helper) {
    super(element, helper);

    this.y = 0;
    this.imageElement = element.querySelector('.pos__image');
    this.textElement = element.querySelector('.pos__text');
    // if (this.textElement) {
    //   this.posTextInstance = new PosText(this, this.textElement);
    // }
  }


  draw() {
    if (this.elementIsInView()) {
      const y = (1 - (this.helper.scrollCenterY - this.elementCenterY)) * -0.08;
      if (this.y !== y) {
        // animate({
        //   elements: this.imageElement,
        //   duration: 100,
        //   transform: [`translateY(${this.y}px)`, `translateY(${y}px)`],
        // });
        this.imageElement.style.transform = `translateY(${this.y}px)`;
      }
      this.y = y;
      if (this.posTextInstance) {
        this.posTextInstance.draw();
      }
    }
  }
}

export default Pos;
