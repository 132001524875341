class Video {
  constructor(element) {
    const videoElement = element.querySelector('video');
    const bttnReplayElement = element.querySelector('.bttn-replay');

    function onBttnReplayClick() {
      videoElement.currentTime = 0;
      videoElement.play();
    }
    if (bttnReplayElement) {
      bttnReplayElement.addEventListener('click', onBttnReplayClick);
    }

    function onPlay() {
      element.classList.remove('is-loading');
      videoElement.classList.remove('is-ended');
      videoElement.classList.remove('is-hidden');
      if (bttnReplayElement) {
        bttnReplayElement.classList.remove('is-visible');
      }
    }
    function onEnded() {
      setTimeout(() => {
        if (videoElement.ended) {
          videoElement.classList.add('is-ended');
          if (bttnReplayElement) {
            bttnReplayElement.classList.add('is-visible');
          }
        }
      }, 100);
    }
    videoElement.addEventListener('play', onPlay);
    videoElement.addEventListener('ended', onEnded);
    videoElement.play();

    setTimeout(() => {
      if (videoElement.readyState < 4 && videoElement.paused) {
        element.classList.add('is-loading');
        videoElement.classList.remove('is-hidden');
      }
    }, 1000);
  }
}

export default Video;
