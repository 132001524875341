import Component from './Component';

class ScrollingVideo extends Component {
  constructor(element, helper, index) {
    super(element, helper);

    const scrollingVideo = this;
    const headerElement = document.querySelector('.header');
    const mainnavElement = document.querySelector('.main-nav');
    const containerElement = element.querySelector('.scrolling-video__container');
    const videoElement = element.querySelector('video');
    const bttnReplayElement = element.querySelector('.bttn-replay');
    const textElement = element.querySelector('.text');
    const mainNavElement = document.querySelector('.main-nav');
    const mainElement = document.querySelector('.main');

    this.headerElement = headerElement;
    this.mainnavElement = mainnavElement;
    this.videoElement = videoElement;
    this.textElement = textElement;
    this.mainNavElement = mainNavElement;
    this.mainElement = mainElement;
    this.currentTime = 0;
    this.isStartAnimationPlayed = false;
    this.isStartAnimationPlaying = false;
    this.hasScrollingEffect = true;
    this.index = index;


    if (/Edge/.test(navigator.userAgent)) {
      containerElement.style.top = '0';
    }
    if (/Firefox/.test(navigator.userAgent)) {
      element.classList.add('has-no-scrolling-effect');
      this.hasScrollingEffect = false;
    }
    if (index !== 0) {
      this.isStartAnimationPlayed = true;
      this.isStartAnimationPlaying = true;
    }
    setTimeout(() => {
      if (videoElement.readyState < 4 && videoElement.paused) {
        element.classList.add('is-loading');
        videoElement.classList.remove('is-hidden');
      }
    }, 500);

    function animateHeader() {
      if (document.scrollingElement.scrollTop <= 0
      && scrollingVideo.hasScrollingEffect
      && navigator.userAgent.indexOf('Trident') === -1
      && navigator.userAgent.indexOf('Edge') === -1) {
        const headerHeight = headerElement.offsetHeight;
        headerElement.style.transition = '400ms';
        headerElement.style.transform = `translateY(${-headerHeight}px)`;
        mainnavElement.style.transition = '400ms';
        mainnavElement.style.transform = `translateY(${-headerHeight}px)`;
        console.log('animateHeader', document.scrollingElement.scrollTop)
        setTimeout(() => {
          headerElement.style.transition = '';
          mainnavElement.style.transition = '';
        }, 450);
      }
    }

    function onPlay() {
      element.classList.remove('is-loading');
      videoElement.classList.remove('is-hidden');
      videoElement.classList.remove('is-ended');
      bttnReplayElement.classList.remove('is-visible');
      if (!scrollingVideo.isStartAnimationPlaying) {
        scrollingVideo.isStartAnimationPlaying = true;
        setTimeout(animateHeader, 1000);
      }
    }
    function onEnded() {
      if (!scrollingVideo.hasScrollingEffect) {
        setTimeout(() => {
          if (videoElement.ended) {
            videoElement.classList.add('is-ended');
            bttnReplayElement.classList.add('is-visible');
          }
        }, 100);
      }
    }
    videoElement.addEventListener('play', onPlay);
    videoElement.addEventListener('ended', onEnded);

    function onBttnReplayClick() {
      videoElement.currentTime = 0;
      videoElement.play();
    }
    bttnReplayElement.addEventListener('click', onBttnReplayClick);


    function onWheel() {
      console.log('onwheel')
      if (scrollingVideo.virtualScrollTop && scrollingVideo.helper.scrollTop === 0) {
        document.scrollingElement.scrollTop = scrollingVideo.virtualScrollTop;
      }
      headerElement.style.transform = '';
      mainnavElement.style.transform = '';
      scrollingVideo.isStartAnimationPlayed = true;
      scrollingVideo.isStartAnimationPlaying = false;
      if (!videoElement.paused) {
        videoElement.pause();
      }
      window.removeEventListener('wheel', onWheel);
      window.removeEventListener('touchmove', onWheel);
    }
    if (this.hasScrollingEffect) {
      window.addEventListener('wheel', onWheel, { passive: true });
      window.addEventListener('touchmove', onWheel, { passive: true });
    }

    window.addEventListener('load', () => {
      requestAnimationFrame(() => {
        if (document.scrollingElement.scrollTop > 0) {
          headerElement.style.transform = '';
          mainnavElement.style.transform = '';
        }
      });
    });

    requestAnimationFrame(() => {
      this.onResize();
    });
  }

  onResize() {
    this.scrollingPositionVideoEnded = this.getScrollingPositionVideoEnded()
  }

  getScrollingPositionVideoEnded() {
    if (this.element && this.mainNavElement) {
      const elementHeight = window.innerHeight * 4;
      const mainNavHeight = this.mainNavElement.offsetHeight;
      const videoElementHeight = this.videoElement.offsetHeight;
      let textElementHeight = 0;
      let visibleTextElementHeight = 0;
      if (this.textElement) {
        textElementHeight = this.textElement.offsetHeight;
        visibleTextElementHeight = window.innerHeight - (mainNavHeight + videoElementHeight);
        if (visibleTextElementHeight < 0) {
          visibleTextElementHeight = 0;
        } else if (visibleTextElementHeight > textElementHeight) {
          visibleTextElementHeight = this.textElement.offsetHeight;
        }
      }
      if (navigator.userAgent.indexOf('Trident') > 0) {
        return videoElementHeight * 0.5; // Trident does not support position: sticky
      }
      return (elementHeight + this.element.offsetTop + visibleTextElementHeight + mainNavHeight) - 69 - textElementHeight - window.innerHeight;
    }
    return false;
  }

  updateVideo() {
    const { scrollTop } = document.scrollingElement;
    const relativeScrollY = scrollTop / this.scrollingPositionVideoEnded;
    let currentTime = relativeScrollY * this.videoElement.duration;
    currentTime = Math.round(currentTime * 100) / 100;
    if (currentTime < 0) {
      currentTime = 0;
    }
    if (currentTime > this.videoElement.duration) {
      currentTime = this.videoElement.duration;
    }
    if (currentTime !== Math.round(this.videoElement.currentTime * 100) / 100) {
      this.videoElement.currentTime = currentTime;
    }
  }

  draw() {
    if (this.hasScrollingEffect) {
      if (this.isStartAnimationPlayed) {
        this.updateVideo();
      } else if (this.isStartAnimationPlaying) {
        const { videoElement } = this;
        const scrollingPositionVideoEnded = this.scrollingPositionVideoEnded;
        const relativeVideoDuration = videoElement.currentTime / videoElement.duration;
        this.virtualScrollTop = (relativeVideoDuration * scrollingPositionVideoEnded);
        if (this.virtualScrollTop === scrollingPositionVideoEnded) {
          this.isStartAnimationPlaying = false;
          this.isStartAnimationPlayed = true;
          document.scrollingElement.scrollTop = this.virtualScrollTop;
          this.headerElement.style.transform = '';
          this.mainnavElement.style.transform = '';
        }
      }
    }
  }
}


export default ScrollingVideo;
