import Component from './Component';

class VideoStart extends Component {
  constructor(element, helper) {
    super(element, helper);

    this.element = element;
    this.scrollTriggerStart = window.innerHeight * 0.5;

    this.buttonElement = element.querySelector('.video-start__button');
    this.buttonElement.addEventListener('click', () => {
      window.scrollTo({
        top: window.innerHeight - 52,
        behavior: 'smooth',
      })
    });

    document.addEventListener('scroll', this.onScroll.bind(this), {
      passive: true,
    });
  }

  onScroll() {
    const { scrollTop } = document.scrollingElement;

    this.buttonElement.style.setProperty('opacity', Math.max(0, 1- scrollTop * 0.005));

    if (scrollTop > this.scrollTriggerStart) {
      const blur = (scrollTop - this.scrollTriggerStart) * 0.09;
      const scale = Math.min(1.02, 1 + (scrollTop - this.scrollTriggerStart) * 0.0001);
      const opacity = Math.max(0.4, 1 - (scrollTop - this.scrollTriggerStart) * 0.0005);
      this.element.style.setProperty('--blur', blur);
      this.element.style.setProperty('--scale', scale);
      this.element.style.setProperty('--opacity', opacity);
    } else {
      this.element.style.removeProperty('--blur');
      this.element.style.removeProperty('--scale');
      this.element.style.removeProperty('--opacity');
    }
  }
}

export default VideoStart;
