class Helper {
  constructor() {
    const helper = this;
    let lastScrollY = document.scrollingElement.scrollTop;
    const mainElement = document.querySelector('.main');
    helper.scrollTop = lastScrollY;
    helper.scrollDirection = 1;

    function updateWindowSizes() {
      helper.windowInnerHeight = window.innerHeight;
      helper.windowInnerWidth = window.innerWidth;
    }

    function onScroll() {
      helper.scrollTop = document.scrollingElement.scrollTop;
      if (helper.scrollTop > lastScrollY) {
        helper.scrollDirection = -1;
      } else {
        helper.scrollDirection = 1;
      }
      helper.scrollCenterY = window.pageYOffset + (window.innerHeight / 2);
      lastScrollY = document.scrollingElement.scrollTop;
    }

    function onResize() {
      updateWindowSizes();
      helper.updateMainSizes();
    }

    window.addEventListener('resize', () => onResize(), { passive: true });
    window.addEventListener('scroll', () => onScroll(), { passive: true });

    this.mainElement = mainElement;

    updateWindowSizes();
    helper.updateMainSizes();
  }

  updateMainSizes() {
    this.mainOffsetTop = this.mainElement.offsetTop;
    this.mainOffsetHeight = this.mainElement.offsetHeight;
  }

  easeInOutCubic(progress) {
    if ((progress *= 2) < 1) {
      return 0.5 * progress ** 3;
    }
    return 0.5 * ((progress -= 2) * progress ** 2 + 2);
  }


  onAllImagesLoaded(loaded) {
    const helper = this;
    const imageElements = document.querySelectorAll('img');
    let loadedImages = 0;

    function onLoad() {
      loadedImages += 1;
      if (loadedImages === imageElements.length) {
        // console.log('all images loaded');
        loaded();
        helper.updateMainSizes();
      }
    }

    [...imageElements].forEach((imageElement) => {
      if (imageElement.complete && imageElement.offsetWidth > 0) {
        onLoad();
      } else {
        imageElement.addEventListener('load', onLoad);
      }
    });
  }
}


export default Helper;
