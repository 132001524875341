/* eslint-disable no-new */
import 'airbnb-browser-shims/browser-only';
import './polyfills/scrollingElement';
import Helper from './Helper';
import Next from './Next';
import Pos from './Pos';
import ImagesGrid from './ImagesGrid';
import Slider3D from './Slider3D';
import ImageFixed from './ImageFixed';
import Video from './Video';
import VideoStart from './VideoStart';
import ScrollingVideo from './ScrollingVideo';
import ScrollingVideos from './ScrollingVideos';
import VimeoVideo from './VimeoVideo';

(() => {
  window.components = [];
  const helper = new Helper();
  [...document.querySelectorAll('.next')].forEach((element) => {
    new Next(element, helper);
  });
  [...document.querySelectorAll('.pos')].forEach((element) => {
    new Pos(element, helper);
  });
  [...document.querySelectorAll('.images-grid')].forEach((element) => {
    new ImagesGrid(element, helper);
  });
  [...document.querySelectorAll('.slider-3d')].forEach((element) => {
    new Slider3D(element, helper);
  });
  [...document.querySelectorAll('.image-fixed')].forEach((element) => {
    new ImageFixed(element, helper);
  });
  [...document.querySelectorAll('.video')].forEach((element) => {
    new Video(element, helper);
  });
  [...document.querySelectorAll('.video-start')].forEach((element) => {
    new VideoStart(element, helper);
  });
  [...document.querySelectorAll('.scrolling-video')].forEach((element, index) => {
    new ScrollingVideo(element, helper, index);
  });
  [...document.querySelectorAll('.scrolling-videos')].forEach((element) => {
    new ScrollingVideos(element, helper);
  });
  document.vimeoVideos = [];
  [...document.querySelectorAll('.vimeo-video')].forEach((element) => {
    document.vimeoVideos.push(new VimeoVideo(element));
  });
  const drawingComponents = window.components.filter(component => typeof component.draw === 'function');

  if (navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) {
    document.body.classList.add('is-ios');
  }

  function draw() {
    drawingComponents.forEach((component) => {
      component.draw();
    });
    requestAnimationFrame(draw);
  }

  function onResize() {
    window.components.forEach(component => component.onResize());
  }

  window.requestAnimationFrame(draw);

  window.addEventListener('resize', onResize);
  document.addEventListener('readystatechange', () => {
    if (document.readyState === 'complete') {
      onResize();
    }
  });

  helper.onAllImagesLoaded(() => {
    window.components.forEach(component => component.onResize());
  });
})();
