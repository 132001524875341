class Component {
  constructor(element, helper) {
    this.element = element;
    this.helper = helper;
    window.components.push(this);
    this.instances = [];
    this.onResize();
  }

  onResize() {
    this.elementOffsetTop = this.element.offsetTop + this.helper.mainOffsetTop;
    this.elementOffsetHeight = this.element.offsetHeight;
    this.elementCenterY = this.elementOffsetTop + (this.elementOffsetHeight / 2);
    this.instances.forEach((instance) => {
      if (typeof instance.onResize === 'function') {
        instance.onResize();
      }
    });
  }

  elementIsInView() {
    const isInView = (this.helper.scrollTop + window.innerHeight + 100 > this.elementOffsetTop
                    && this.helper.scrollTop - 100 < this.elementOffsetTop + this.elementOffsetHeight);
    return isInView;
  }
}


export default Component;
